import * as React from "react"
import { Link, graphql } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Top from '../components/top'
import Footer from '../components/footer'
import Header from "../components/header"
import Contact from '../components/contact'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const BlogPage = ({data}) => {
  const entry = data.prismicBlogPage
  const entries = data.allPrismicBlog
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Top />
      <Header />

      <div className="w-full py-16 relative">
        <div className="absolute -inset-0">
          <GatsbyImage className="w-full h-full object-cover block" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
        </div>
        <div className="absolute -inset-0">
          <div className="w-full h-full bg-black opacity-50"></div>
        </div>
        <div className="relative">
          <div className="w-10/12 mx-auto max-w-screen-xl">
            <div className="flex-none sm:flex justify-between gap-16">
              <div className="w-full sm:w-8/12">
                <div className="text-4xl sm:text-5xl text-white">
                  <h1><span className="text-white mr-3">|</span> {entry.data.heading}</h1>
                </div>
                {entry.data.text === null ? <div className="mt-6 prose prose-lg text-grey-6">
                  <PrismicRichText field={entry.data.text.richText} />
                </div> : ''}
              </div>
              <div className="hidden sm:w-4/12">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-4">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex items-center justify-end gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" /></svg>
            <Link to="/">Home</Link>
          </div>
        </div>
      </div>

      <div className="w-full py-16 border-t border-grey-c">
        <div className="w-10/12 mx-auto max-w-screen-xl">
          <div className="flex justify-between items-start">
            <div className="w-full">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-16">
                {entries.edges.map((entry, i) => (
                  <div key={`entry_${i}`} className="group">
                    <Link to={`/news/${entry.node.uid}/`}>
                      <figure data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease" className="mb-6">
                        <GatsbyImage className="group-hover:scale-105 transition-all w-full h-full object-cover block" image={entry.node.data.image.gatsbyImageData} alt={entry.node.data.heading.text} />
                      </figure>
                      <div className="prose prose-h3:font-normal group-hover:prose-h3:text-red">
                        <h6>{entry.node.data.date}</h6>
                        <h3>{entry.node.data.heading ?? ''} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></h3>
                        <p><PrismicRichText field={entry.node.data.intro.richText} /></p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Contact />
      <Footer />
    </>
  )
}

export const query = graphql`
query {
  prismicBlogPage {
    data {
      meta_description
      page_title
      heading
      image {
        gatsbyImageData
        alt
      }
      text {
        richText
      }
    }
  }
  allPrismicBlog(sort: {fields: data___date, order: DESC}) {
    edges {
      node {
        uid
        data {
          date(formatString: "MMM DD, YYYY")
          heading
          text {
            richText
          }
          image {
            gatsbyImageData
            alt
          }
          intro {
            richText
          }
        }
      }
    }
  }
}
`

export default BlogPage
