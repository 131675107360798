import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby'
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'

const Contact = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          prismicCallToAction {
            data {
              heading
              text {
                richText
              }
              button
              page_link {
                uid
                type
              }
            }
          }
        }
      `}
      render={data => (
        <div className="w-full py-16 relative">
          <div className="absolute -inset-0">
            <img className="w-full h-full object-cover block" src="/img/stock-photo-the-process-of-manufacturing-a-part-of-complex-shape-from-plastic-on-a-cnc-milling-machine-in-a-2083036498.jpg" alt="" />
          </div>
          <div className="absolute -inset-0">
            <div className="w-full h-full bg-grey-e opacity-1"></div>
          </div>
          <div className="relative">
            <div className="w-10/12 mx-auto max-w-screen-xl">
              <div className="flex-none sm:flex justify-between gap-12">
                <div className="w-full sm:w-8/12">
                  <div className="text-4xl sm:text-5xl text-black">
                    <h2>{data.prismicCallToAction.data.heading}</h2>
                  </div>
                  <div className="mt-3 prose prose-lg text-grey-6">
                    <PrismicRichText field={data.prismicCallToAction.data.text.richText} />
                  </div>
                  <div className="mt-12">
                    <Link to={linkResolver(data.prismicCallToAction.data.page_link)} className="rounded-md text-lg bg-red text-white px-4 pt-2 pb-2">{data.prismicCallToAction.data.button} <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                  </div>
                </div>
                <div className="w-full sm:w-4/12">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default Contact;
